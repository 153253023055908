import React, { useState } from 'react';
import { useFirebase } from '../../context/Firebase';
import { RecaptchaVerifier, PhoneAuthProvider, signInWithPhoneNumber, signInWithCredential } from 'firebase/auth';

const Login = (props) => {
    const baseClass = "log-in";
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    const [phone, setPhone] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const { auth } = useFirebase();

    const sendOTP = async () => {
        if(phone !== '') {
            try {
                var recaptcha = recaptchaVerifier;
                if(recaptcha == null) {
                    recaptcha = new RecaptchaVerifier(auth, 'recaptcha-container', {
                        size: 'invisible',
                        callback: (response) => {},
                        appVerificationDisabledForTesting: true,
                    });
                    setRecaptchaVerifier(recaptcha);
                }
                const result = await signInWithPhoneNumber(auth, `+91${phone}`, recaptcha);
                setConfirmationResult(result);
            } catch (error) {
                console.error('Error sending code:', error.code, error.message);
            }
        }
    };

    const verifyOTP = async () => {
        if(verificationCode !== '') {
            try {
                await signInWithCredential(auth, PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode));
            } catch (error) {
                console.error('Error verifying code:', error.code, error.message);
            }
        }
    };

    return (
        <div className={baseClass}>
            <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{ __html: "Please enter your mobile number" }} />
            <div id="recaptcha-container" style={{display: "none"}} />
            <input type="text" placeholder="Enter your number" value={phone} onChange={(e) => setPhone(e.target.value)} />
            {confirmationResult != null && <input type="text" placeholder="Enter OTP" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />}
            <div className={`${baseClass}__btn-wrapper`}>
                <button className={`${baseClass}__btn ${confirmationResult != null ? "resend-btn" : ""}`} onClick={sendOTP} dangerouslySetInnerHTML={{ __html: confirmationResult == null ? "Send OTP" : "Resend OTP" }} />
                {confirmationResult != null && <button className={`${baseClass}__btn`} onClick={verifyOTP} dangerouslySetInnerHTML={{ __html: "Verify OTP" }} />}
            </div>
        </div>
    );
};

export default Login;