import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../context/Firebase';
import { onAuthStateChanged, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

const Signup = (props) => {
    const baseClass = "sign-up";
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('female');
    const [user, setUser] = useState();
    const { auth, firestore } = useFirebase();

    useEffect(() => { onAuthStateChanged(auth, (user) => {if(user) { setUser(user); }}); });

    const signUp = async () => {
        if(user !== null && name !== '' && email !== '') {
            updateProfile(user, {
                displayName: name
            }).then(() => {
                console.log('Display name updated successfully');
            }).catch((error) => {
                console.error('Error sending code:', error.code, error.message);
            });
            const userData = {
                name: name,
                email: email,
                gender: gender,
                phone: user.phoneNumber
            }
            await setDoc(doc(firestore, "users", user.uid), userData);
            props.closeModal();
        }
    }

    return (
        <div className={baseClass}>
            <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{ __html: "Seems like you're logging in for first time. Please enter the details to setup account" }} />
            <input type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
            <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <div className={`${baseClass}__gender`}>
                <label>
                    <input type="radio" value="female" checked={gender === 'female'} onChange={(e) => setGender(e.target.value)} />
                    Female
                </label>
                <div className={`${baseClass}__gender__separator`} />
                <label>
                    <input type="radio" value="male" checked={gender === 'male'} onChange={(e) => setGender(e.target.value)} />
                    Male
                </label>
            </div>
            <button className={`${baseClass}__btn`} onClick={signUp} dangerouslySetInnerHTML={{ __html: "Sign up" }} />
        </div>
    )
}

export default Signup;