import { signOut } from 'firebase/auth';
import React from 'react';
import { useFirebase } from '../../context/Firebase';

const Profile = (props) => {
    const baseClass = "profile";
    const { auth } = useFirebase();

    const logOut = () => {
        signOut(auth).then(() => props.clearAuth()).catch((error) => { console.error('Error sending code:', error.code, error.message); });
    }

    return (
        <div className={baseClass}>
            <div className={`${baseClass}__name`} dangerouslySetInnerHTML={{__html: props.user.displayName}} />
            <div className={`${baseClass}__details`}>
                <div className={`${baseClass}__details__items`}>
                    <div className={`${baseClass}__details__label`} dangerouslySetInnerHTML={{__html: "Phone No"}} />
                    <div dangerouslySetInnerHTML={{__html: ":"}} />
                    <div className={`${baseClass}__details__value`} dangerouslySetInnerHTML={{__html: props.user.phoneNumber}} />
                </div>
                <div className={`${baseClass}__details__items`}>
                    <div className={`${baseClass}__details__label`} dangerouslySetInnerHTML={{__html: "Email"}} />
                    <div dangerouslySetInnerHTML={{__html: ":"}} />
                    <div className={`${baseClass}__details__value`} dangerouslySetInnerHTML={{__html: props.userData.email}} />
                </div>
                <div className={`${baseClass}__details__items`}>
                    <div className={`${baseClass}__details__label`} dangerouslySetInnerHTML={{__html: "Gender"}} />
                    <div dangerouslySetInnerHTML={{__html: ":"}} />
                    <div className={`${baseClass}__details__value`} dangerouslySetInnerHTML={{__html: props.userData.gender.toUpperCase()}} />
                </div>
            </div>
            <button className={`${baseClass}__btn`} onClick={logOut} dangerouslySetInnerHTML={{ __html: "Log out" }} />
        </div>
    )
}

export default Profile;