import { getDownloadURL, listAll, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../context/Firebase';
import HeaderAuthModal from './HeaderAuthModal';
import { onAuthStateChanged } from 'firebase/auth';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const baseClass = "header";
    const loginMessage = "Log In";

    const [width, setWidth] = useState(window.innerWidth);
    const [mobileMenuClosed, setMobileMenuClosed] = useState(true);
    const [logo, setLogo] = useState(require('../../assets/images/logo.png'));
    const [currentUser, setCurrentUser] = useState();
    const [showAuthModal, setShowAuthModal] = useState(false);
    const { auth, storage } = useFirebase();

    useEffect(() => {
        listAll(ref(storage, 'logos')).then((res) => {
            if(res.items.length > 0) {
                getDownloadURL(res.items[0]).then((url) => {
                    setLogo(url);
                }).catch((error) => {console.log("url fetch error : ", error.message)});
            }
        }).catch((error) => {console.log("image ref error : ", error.message)});
    }, [storage]);

    useEffect(() => {
        if (!showAuthModal) {
            onAuthStateChanged(auth, (user) => {
                if(user) { setCurrentUser(user); }
            });
        }
    }, [showAuthModal, auth]);

    useEffect(() => {
        const detectCurrentWidth = () => {
            setWidth(window.innerWidth);
            if(window.innerWidth > 700) {
                setMobileMenuClosed(true);
            } else if(window.innerWidth <= 700 && !mobileMenuClosed) {
                setMobileMenuClosed(false);
            }
        }
        window.addEventListener('resize', detectCurrentWidth);
        return () => {
            window.removeEventListener('resize', detectCurrentWidth);
        }
    }, [width, mobileMenuClosed]);

    return (
        <div className={baseClass}>
            <nav className={`${baseClass}__container`}>
                <NavLink to="/"> <img className={`${baseClass}__logo`} src={logo} alt="logo" /> </NavLink>
                <div>
                    <ul className={`${baseClass}__items${mobileMenuClosed ? '' : '__open'}`}>
                        <li>
                            {currentUser && currentUser.displayName ?
                                <div dangerouslySetInnerHTML={{__html: currentUser.displayName}} onClick={() => setShowAuthModal(true)} />
                                : <div dangerouslySetInnerHTML={{__html: loginMessage}} onClick={() => setShowAuthModal(true)} />
                            }
                        </li>
                    </ul>
                    <div className={`${baseClass}__mobile`}>
                        <i className={`fa-solid fa-${mobileMenuClosed ? "bars" : "xmark"}`} onClick={() => setMobileMenuClosed(!mobileMenuClosed)} />
                    </div>
                </div>
            </nav>
            {showAuthModal && <HeaderAuthModal user={currentUser} clearAuth={() => setCurrentUser()} closeModal={() => setShowAuthModal(false)} />}
        </div>
    )
}

export default Header;