import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { useFirebase } from '../../context/Firebase';

const TopBanner = (props) => {
    const baseClass = "top-banner";

    const [bannerImages, setBannerImages] = useState([]);
    const [homeImages, setHomeImages] = useState();
    const [width, setWidth] = useState(window.innerWidth);
    const { storage } = useFirebase();

    useEffect(() => {
        if(props.carousel) {
            listAll(ref(storage, 'bannerImages/topBannerImages')).then((res) => {
                if(res.items.length > 0) {
                    let urls = [];
                    res.items.forEach((imageRef) => {
                        getDownloadURL(imageRef).then((url) => {
                            urls = [...urls, url];
                            setBannerImages(urls);
                        }).catch((error) => {console.log("url fetch error : ", error.message)});
                    });
                }
            }).catch((error) => {console.log("image ref error : ", error.message)});
        }
    }, [storage, props.carousel]);

    useEffect(() => {
        listAll(ref(storage, `bannerImages/homeBannerImages/${width >= 700 ? 'desktop' : 'mobile'}`)).then((res) => {
            if(res.items.length > 0) {
                res.items.forEach((imageRef) => {
                    getDownloadURL(imageRef).then((url) => {
                        setHomeImages(url);
                    }).catch((error) => {console.log("url fetch error : ", error.message)});
                });
            }
        }).catch((error) => {console.log("image ref error : ", error.message)});
    }, [storage, width]);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {window.removeEventListener('resize', handleResize)};
    }, []);

    return (
        <div className={baseClass}>
            {!props.carousel ? homeImages && <img style={{height: `${window.innerHeight - 83}px`}} className={`${baseClass}__home-image`} src={homeImages} alt="please wait loading banner"/>
                : bannerImages.length > 0 && <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className={`${baseClass}__swiper`}
                >
                    {bannerImages.map((bannerImage, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <img className={`${baseClass}__image`} src={bannerImage} alt="Banner 1" />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            }
        </div>
    )
}

export default TopBanner;